import logo from '../../logo.svg';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios, { Axios, AxiosHeaders } from 'axios';

// Components
import Head from '../../components/head';
import Header from '../../components/header';
//import Footer from '../../components/footer';

function App() {
  const [setting, setSetting] = useState({
    title: '',
    description: '',
    tags: '',
    image: null,
    instagram: '',
    pinterest: '',
    twitter: '',
    phone: '',
    address: '',
    email: '',
    imagePreview: null
  });

  useEffect(() => {
    const FetchSettings = async () => {
      try {
        const response = await axios.get('https://base-api.mahmutguveninsaat.com/settings', {});

        let result = response.data;
        if (result['success']) {
          console.log('Geldi!');
          if (result['data']['title'] != null)
            setting.title = result['data']['title'];
          if (result['data']['tags'] != null)
            setting.tags = result['data']['tags'];
          if (result['data']['description'] != null)
            setting.description = result['data']['description'];
          if (result['data']['instagram'] != null)
            setting.instagram = result['data']['instagram'];
          if (result['data']['twitter'] != null)
            setting.twitter = result['data']['twitter'];
          if (result['data']['pinterest'] != null)
            setting.pinterest = result['data']['pinterest'];
          if (result['data']['phone'] != null)
            setting.phone = result['data']['phone'];
          if (result['data']['address'] != null)
            setting.address = result['data']['address'];
          if (result['data']['email'] != null)
            setting.email = result['data']['email'];

          setSetting({ ...setting, setting });
          console.log(setting);
        } else {
          console.log('Gelmedi.');
        }
      } catch (err) {
        console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        console.error('Login error:', err);
      }
    }
    FetchSettings();
  }, []);

  const tab = (tabName, element) => {
    var i;
    var x = document.getElementsByClassName("tab");
    var buttons = document.getElementsByClassName("bar-button");

    // Hide all tabs and remove b-active class from all buttons
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    for (i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("b-active");
    }

    // Show the selected tab and add b-active class to the corresponding button
    document.getElementById(tabName).style.display = "block";
    element.classList.add("b-active");
  }

  return (
    <div className="main">
      <Head content={{ page: { title: "Projelerimiz" } }} />

      <div className="frame">
        <Header content={{ page: { title: "Detay", type: 'project/details' }, setting: setting }} />

        <div className="hakkimizda" id="hakkimizda">
          <div className="flex">
            <div className="left">
              <div className="hakkimizda-mini" data-aos="fade-right">
                <div className="slogan flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={30}
                    height={18}
                    viewBox="0 0 30 18"
                    fill="none"
                  >
                    <path
                      d="M15.0288 0.249999L15.4434 8.55958L30.0001 9.04926L15.4405 9.44333L14.9713 17.75L14.5567 9.44042L5.76938e-05 8.95074L14.5596 8.55667L15.0288 0.249999Z"
                      fill="url(#paint0_radial_8_5905)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_8_5905"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(15.0001 9) rotate(135.188) scale(10.6067)"
                      >
                        <stop stopColor="#1E1E1C" />
                        <stop offset={1} stopColor="#1E1E1C" />
                      </radialGradient>
                    </defs>
                  </svg>{" "}
                  BİZİ YAKINDAN TANIYIN
                </div>
                <h1>Hakkımızda</h1>
                <div className="w3-bar w3-black flex">
                  <button
                    className="bar-button b-active"
                    onClick={(e) => tab('bizi-taniyin', e.target)}
                  >
                    Bizi Tanıyın
                  </button>
                  <button
                    className="bar-button"
                    onClick={(e) => tab('vizyonumuz', e.target)}
                  >
                    Vizyonumuz
                  </button>
                  <button
                    className="bar-button"
                    onClick={(e) => tab('misyonumuz', e.target)}
                  >
                    Misyonumuz
                  </button>
                </div>
                <div
                  id="bizi-taniyin"
                  className="w3-container w3-display-container tab"
                >
                  <p>
                    İnşaat sektöründe uzun yıllardır var olan bir geleneği devam
                    ettirerek, [Şirket İsmi] olarak her projeyle birlikte yarattığımız
                    güvenilirlik ve kalite imzasıyla biliniyoruz. Müşterilerimizin
                    hayallerini gerçeğe dönüştürmek için her ayrıntıya özen
                    gösteriyoruz ve işimizi aşkla yapıyoruz. Deneyimli ekibimiz,
                    yenilikçi teknolojiler ve sektördeki en iyi uygulamaları bir araya
                    getirerek, projelerimizi geleceğe taşıyoruz.
                    <br />
                    <br />
                    Gelecek nesillere sürdürülebilir ve etkili yapılar bırakmak için
                    çaba harcıyor, sektördeki liderliğimizi daha da ileriye taşımak
                    için durmaksızın çalışıyoruz.
                  </p>
                </div>
                <div
                  id="vizyonumuz"
                  className="w3-container w3-display-container tab"
                  style={{ display: "none" }}
                >
                  <p>
                    Geleceği inşa ederken, kalitemizle ve yenilikçi yaklaşımımızla
                    sektörde öncü olmayı hedefliyoruz. Çevreye duyarlı, sürdürülebilir
                    ve modern yapılarla, yaşam alanlarını yeniden tanımlayarak
                    müşterilerimize <b>güvenli</b>, estetik ve fonksiyonel çözümler
                    sunuyoruz. Teknoloji ve insan odaklı projelerimizle, topluma ve
                    ekonomiye değer katarken, çalışanlarımızın ve paydaşlarımızın da
                    gelişimine katkıda bulunmayı amaçlıyoruz.
                  </p>
                </div>
                <div
                  id="misyonumuz"
                  className="w3-container w3-display-container tab"
                  style={{ display: "none" }}
                >
                  <p>
                    Müşterilerimize en yüksek kalitede hizmet sunarak, dayanıklı ve
                    estetik yapılar inşa etmek için çalışıyoruz. Teknolojiyi ve
                    yenilikleri benimseyerek, sürdürülebilir ve çevre dostu projelere
                    imza atmayı amaçlıyoruz. Müşteri memnuniyetini en üst düzeyde
                    tutarken, güvenlik ve kalite standartlarından ödün vermeden, her
                    aşamada profesyonel ve şeffaf bir yaklaşım sergiliyoruz.
                    Çalışanlarımızın gelişimine ve mutluluğuna önem vererek, takım
                    ruhunu ve iş birliğini teşvik ediyoruz. Etik değerlere bağlı
                    kalarak, topluma değer katan, güvenilir ve örnek bir inşaat
                    şirketi olmayı hedefliyoruz.{" "}
                  </p>
                </div>
              </div>
              <div className="neler-yapiyoruz" data-aos="fade-right">
                <h2>Neler Yapıyoruz?</h2>
                <div className="flex">
                  <div className="left">
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Tadilat
                    </div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Renovasyon
                    </div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Restorasyon
                    </div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      İç Tasarım Paketleri
                    </div>
                  </div>
                  <div className="right">
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Planlama - Konstrüksiyon
                    </div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Proje Yönetimi
                    </div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Mimari Müşavirlik ve Kontrolörlük
                    </div>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={8}
                        height={8}
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.33333 3.33333H4.66667V0.666667C4.66667 0.489856 4.59643 0.320287 4.4714 0.195262C4.34638 0.070238 4.17681 0 4 0C3.82319 0 3.65362 0.070238 3.5286 0.195262C3.40357 0.320287 3.33333 0.489856 3.33333 0.666667V3.33333H0.666667C0.489856 3.33333 0.320287 3.40357 0.195262 3.5286C0.070238 3.65362 0 3.82319 0 4C0 4.17681 0.070238 4.34638 0.195262 4.4714C0.320287 4.59643 0.489856 4.66667 0.666667 4.66667H3.33333V7.33333C3.33333 7.51014 3.40357 7.67971 3.5286 7.80474C3.65362 7.92976 3.82319 8 4 8C4.17681 8 4.34638 7.92976 4.4714 7.80474C4.59643 7.67971 4.66667 7.51014 4.66667 7.33333V4.66667H7.33333C7.51014 4.66667 7.67971 4.59643 7.80474 4.4714C7.92976 4.34638 8 4.17681 8 4C8 3.82319 7.92976 3.65362 7.80474 3.5286C7.67971 3.40357 7.51014 3.33333 7.33333 3.33333Z"
                          fill="#1E1E1C"
                        />
                      </svg>
                      Mimari Müşavirlik ve Kontrolörlük
                    </div>
                  </div>
                </div>
              </div>
              <div className="iletisime-gec" data-aos="fade-right">
                <h2>Aklınızda bir proje mi var? Bizimle iletişime geçin!</h2>
                <a href="#" className="flex" style={{ justifyContent: "start" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_8_6046)">
                      <path
                        d="M17.25 8.24998C17.0511 8.24998 16.8603 8.17096 16.7196 8.03031C16.579 7.88966 16.5 7.69889 16.5 7.49998C16.4984 5.90917 15.8657 4.38397 14.7409 3.25909C13.616 2.13422 12.0908 1.50157 10.5 1.49998C10.3011 1.49998 10.1103 1.42096 9.96965 1.28031C9.829 1.13965 9.74998 0.948889 9.74998 0.749977C9.74998 0.551065 9.829 0.360299 9.96965 0.219647C10.1103 0.0789946 10.3011 -2.30243e-05 10.5 -2.30243e-05C12.4884 0.00216067 14.3948 0.793038 15.8009 2.19909C17.2069 3.60514 17.9978 5.51152 18 7.49998C18 7.69889 17.921 7.88966 17.7803 8.03031C17.6397 8.17096 17.4489 8.24998 17.25 8.24998ZM15 7.49998C15 6.3065 14.5259 5.16191 13.682 4.318C12.838 3.47408 11.6935 2.99998 10.5 2.99998C10.3011 2.99998 10.1103 3.07899 9.96965 3.21965C9.829 3.3603 9.74998 3.55106 9.74998 3.74998C9.74998 3.94889 9.829 4.13965 9.96965 4.28031C10.1103 4.42096 10.3011 4.49998 10.5 4.49998C11.2956 4.49998 12.0587 4.81605 12.6213 5.37866C13.1839 5.94127 13.5 6.70433 13.5 7.49998C13.5 7.69889 13.579 7.88966 13.7196 8.03031C13.8603 8.17096 14.0511 8.24998 14.25 8.24998C14.4489 8.24998 14.6397 8.17096 14.7803 8.03031C14.921 7.88966 15 7.69889 15 7.49998ZM16.6372 16.623L17.3197 15.8362C17.7541 15.4004 17.9981 14.8102 17.9981 14.1949C17.9981 13.5795 17.7541 12.9893 17.3197 12.5535C17.2965 12.5302 15.492 11.142 15.492 11.142C15.0589 10.7297 14.4836 10.5002 13.8857 10.501C13.2878 10.5018 12.7132 10.733 12.2812 11.1465L10.8517 12.351C9.68486 11.868 8.62489 11.1594 7.73269 10.2656C6.84049 9.37187 6.13364 8.31068 5.65273 7.14298L6.85273 5.71798C7.26651 5.28611 7.49795 4.71138 7.49893 4.11328C7.49991 3.51517 7.27035 2.9397 6.85798 2.50648C6.85798 2.50648 5.46823 0.704227 5.44498 0.680977C5.01707 0.250288 4.43689 0.00524349 3.8298 -0.00121194C3.22271 -0.00766736 2.63745 0.224985 2.20048 0.646477L1.33798 1.39648C-3.75752 7.30798 7.21498 18.1957 13.3215 18C13.9381 18.0036 14.5492 17.8835 15.1187 17.647C15.6882 17.4105 16.2045 17.0623 16.6372 16.623Z"
                        fill="#1E1E1C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8_6046">
                        <rect width={18} height={18} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  {setting.phone}
                </a>
                <p>
                  
                </p>
              </div>
              <div className="soc" data-aos="fade-right">
                <a href={"https://instagram.com/" + setting.instagram}>Instagram</a>
                <a href={"https://www.youtube.com/" + setting.instagram}>Youtube</a>
                <a href="/iletisim">Bize Ulaş</a>
              </div>
            </div>
            <div className="right">
              <img src="img/hakkimizda.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;