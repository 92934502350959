import logo from '../../logo.svg';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios, { Axios, AxiosHeaders } from 'axios';

// Components
import Head from '../../components/head';
import Header from '../../components/header';
//import Footer from '../../components/footer';

var fetchProjects_count = 0;

function App() {
  const [rows, setRows] = useState([]);
  const [setting, setSetting] = useState({
    title: '',
    description: '',
    tags: '',
    image: null,
    instagram: '',
    pinterest: '',
    twitter: '',
    phone: '',
    address: '',
    email: '',
    imagePreview: null
  });

  useEffect(() => {
    const FetchSettings = async () => {
      try {
        const response = await axios.get('https://base-api.mahmutguveninsaat.com/settings', {});

        let result = response.data;
        if (result['success']) {
          console.log('Geldi!');
          if (result['data']['title'] != null)
            setting.title = result['data']['title'];
          if (result['data']['tags'] != null)
            setting.tags = result['data']['tags'];
          if (result['data']['description'] != null)
            setting.description = result['data']['description'];
          if (result['data']['instagram'] != null)
            setting.instagram = result['data']['instagram'];
          if (result['data']['twitter'] != null)
            setting.twitter = result['data']['twitter'];
          if (result['data']['pinterest'] != null)
            setting.pinterest = result['data']['pinterest'];
          if (result['data']['phone'] != null)
            setting.phone = result['data']['phone'];
          if (result['data']['address'] != null)
            setting.address = result['data']['address'];
          if (result['data']['email'] != null)
            setting.email = result['data']['email'];

          setSetting({ ...setting, setting });
          console.log(setting);
        } else {
          console.log('Gelmedi.');
        }
      } catch (err) {
        console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        console.error('Login error:', err);
      }
    }
    FetchSettings();
  }, []);

  useEffect(() => {
    const FetchProjects = async () => {
      try {
        console.log("requesttt");

        console.log({
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token')
          }
        });

        const response = await axios.get('https://base-api.mahmutguveninsaat.com/projects', {});

        let result = response.data;

        console.log(result);

        if (result['success']) {
          console.log('Geldi!');
          console.log(result.data);

          // Her 5 projede bir yeni satır oluştur
          let _rows = [];
          for (let i = 0; i < result.data.length; i += 5) {
            let rowProjects = result.data.slice(i, i + 5);
            _rows.push(rowProjects);
          }
          
          console.log(_rows);

          setRows([..._rows]);
          console.log("rows--");

          console.log(rows);
        } else {
          console.log('Gelmedi.');
        }
      } catch (err) {
        console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        console.error('Login error:', err);
      }
      fetchProjects_count++;
    }
    if (fetchProjects_count == 0)
      FetchProjects();
  }, []);

  return (
    <div className="main">
      <Head content={{ page: { title: "Projelerimiz" } }} />

      <div className="frame">
        <Header content={{ page: { title: "Detay", type: 'projects' }, setting: setting }} />
        <div className="projelerimiz" id="projelerimiz">
          <div className="header-2" data-aos="fade-down">
            <div className="flex">
              <div className="left">
                <div className="slogan flex">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={30}
                    height={18}
                    viewBox="0 0 30 18"
                    fill="none"
                  >
                    <path
                      d="M15.0288 0.249999L15.4434 8.55958L30.0001 9.04926L15.4405 9.44333L14.9713 17.75L14.5567 9.44042L5.76938e-05 8.95074L14.5596 8.55667L15.0288 0.249999Z"
                      fill="url(#paint0_radial_8_5905)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_8_5905"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(15.0001 9) rotate(135.188) scale(10.6067)"
                      >
                        <stop stopColor="#1E1E1C" />
                        <stop offset={1} stopColor="#1E1E1C" />
                      </radialGradient>
                    </defs>
                  </svg>{" "}
                  EN İYİ HİZMET İÇİN BURADAYIZ
                </div>
                <h2>Projelerimiz</h2>
              </div>
              <div className="right aciklama">
                <p>
                  Her bir projemizde müşterilerimizin ihtiyaçlarına ve beklentilerine
                  en uygun çözümleri sunmak için çabaladık.
                </p>
              </div>
            </div>
          </div>
          {rows.map((row, rowIndex) => (
            <div className="flex1 projeler" data-aos="fade-up">
              <div className="container">
                {row.slice(0, 4).map((project, index) => (
                  <a href={project.seo}>
                    <div className="card">
                      <img src={project.cover_image} alt={project.title} />
                      <div className="overlay">
                        <div className="text">İncele</div>
                      </div>
                      <div className="title">
                        {index + 1}
                        <br />
                        {project.title}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              {row[4] && (
                <div className="right">
                  <a href={row[4].seo}>
                    <div className="card" id="pr-5">
                      <img src={row[4].cover_image} alt={row[4].title} />
                      <div className="overlay">
                        <div className="text">İncele</div>
                      </div>
                      <div className="title">
                        {rowIndex * 5 + 1}
                        <br />
                        {row[4].title}
                      </div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


/*
     <div className="container">
              {projects.slice(0, 4).map((project, index) => (
                <a href={project.seo}>
                  <div className="card">
                    <img src={project.cover_image} alt={project.title} />
                    <div className="overlay">
                      <div className="text">İncele</div>
                    </div>
                    <div className="title">
                      {index + 1}
                      <br />
                      {project.title}
                    </div>
                  </div>
                </a>
              ))}
            </div>
            <div className="right">
              {projects.slice(4, 5).map((project, index) => (
                <a href={project.seo}>
                  <div className="card" id="pr-5">
                    <img src={project.cover_image} alt={project.title} />
                    <div className="overlay">
                      <div className="text">İncele</div>
                    </div>
                    <div className="title">
                      {index + 1}
                      <br />
                      {project.title}
                    </div>
                  </div>
                </a>
              ))}
            </div>


*/
export default App;