import logo from '../../logo.svg';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import axios, { Axios, AxiosHeaders } from 'axios';

// Components
import Head from '../../components/head';
import Header from '../../components/header';
//import Footer from '../../components/footer';

function App() {
    const { seo } = useParams();
    const [project, setProject] = useState({
        title: '',
        seo: '',
        description: '',
        date: '',
        type: '',
        location: '',
        p_key: '',
        images: [],
        youtube: null,
        created_at: ''
    });
    const [setting, setSetting] = useState({
        title: '',
        description: '',
        tags: '',
        image: null,
        instagram: '',
        pinterest: '',
        twitter: '',
        phone: '',
        address: '',
        email: '',
        imagePreview: null
    });

    useEffect(() => {
        const FetchSettings = async () => {
            try {
                const response = await axios.get('https://base-api.mahmutguveninsaat.com/settings', {});

                let result = response.data;
                if (result['success']) {
                    console.log('Geldi!');
                    if (result['data']['title'] != null)
                        setting.title = result['data']['title'];
                    if (result['data']['tags'] != null)
                        setting.tags = result['data']['tags'];
                    if (result['data']['description'] != null)
                        setting.description = result['data']['description'];
                    if (result['data']['instagram'] != null)
                        setting.instagram = result['data']['instagram'];
                    if (result['data']['twitter'] != null)
                        setting.twitter = result['data']['twitter'];
                    if (result['data']['pinterest'] != null)
                        setting.pinterest = result['data']['pinterest'];
                    if (result['data']['phone'] != null)
                        setting.phone = result['data']['phone'];
                    if (result['data']['address'] != null)
                        setting.address = result['data']['address'];
                    if (result['data']['email'] != null)
                        setting.email = result['data']['email'];

                    setSetting({ ...setting, setting });
                    console.log(setting);
                } else {
                    console.log('Gelmedi.');
                }
            } catch (err) {
                console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
                console.error('Login error:', err);
            }
        }
        FetchSettings();
    }, []);

    useEffect(() => {
        const FetchProject = async () => {
            try {
                const response = await axios.get('https://base-api.mahmutguveninsaat.com/projects/' + seo, {});

                console.log("İSTEK ATILDI");
                console.log(response);

                let result = response.data;
                if (result['success']) {
                    console.log('Geldi!');
                    setProject({ ...result.data });
                    console.log(project);
                } else {
                    console.log('Gelmedi.');
                }
            } catch (err) {
                console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
                console.error('Login error:', err);
            }
        }
        FetchProject();
    }, []);

    return (
        <div className="main">
            <Head content={{ page: { title: "Detay", type: 'project/details' } }} />
            <div className="frame detay-1">
                <div className="frame">
                    <Header content={{ page: { title: "Detay", type: 'project/details' }, setting: setting }} />

                    <div className="detay" data-aos="fade-down">
                        <div className="header-2">
                            <div className="flex2">
                                <div className="left">
                                    <div className="slogan flex" style={{ justifyContent: "start" }}>
                                        {" "}
                                        <svg
                                            style={{ marginRight: 10 }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={30}
                                            height={18}
                                            viewBox="0 0 30 18"
                                            fill="none"
                                        >
                                            <path
                                                d="M15.0288 0.249999L15.4434 8.55958L30.0001 9.04926L15.4405 9.44333L14.9713 17.75L14.5567 9.44042L5.76938e-05 8.95074L14.5596 8.55667L15.0288 0.249999Z"
                                                fill="url(#paint0_radial_8_5905)"
                                            />
                                            <defs>
                                                <radialGradient
                                                    id="paint0_radial_8_5905"
                                                    cx={0}
                                                    cy={0}
                                                    r={1}
                                                    gradientUnits="userSpaceOnUse"
                                                    gradientTransform="translate(15.0001 9) rotate(135.188) scale(10.6067)"
                                                >
                                                    <stop stopColor="#1E1E1C" />
                                                    <stop offset={1} stopColor="#1E1E1C" />
                                                </radialGradient>
                                            </defs>
                                        </svg>{" "}
                                        PROJE DETAYI
                                    </div>
                                    <h2>{project.title}</h2>
                                    <p>
                                        {project.description}
                                    </p>
                                </div>
                                <div className="flex right">
                                    <div className="pr-dt">
                                        <i>Lokasyon</i>
                                        <p>{project.location}</p>
                                    </div>
                                    <div className="pr-dt">
                                        <i>Proje Türü</i>
                                        <p>{project.type}</p>
                                    </div>
                                    <div className="pr-dt">
                                        <i>Proje Tarihi</i>
                                        <p>{project.date}</p>
                                    </div>
                                    <div className="pr-dt">
                                        <i>Proje Ekibi</i>
                                        <p>{'Mahmut Güven Tasarım & İnşaat'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="album">
                        {project.images.map((image, index) => (
                            <img src={image.image} alt={project.title + ' ' + index} data-aos="fade-up" />
                        ))}
                        {project.youtube != null && (
                            <iframe className='video-frame' src={project.youtube} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

//<iframe width="100%" data-aos="fade-down" height="400" src={project.youtube} title={project.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

export default App;