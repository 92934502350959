import { BrowserRouter as Router, Routes, Route, useSearchParams, useParams } from 'react-router-dom';
import './App.css';
import React from 'react';

import Home from'./routers/home';
import User from'./routers/user';

// Routers
const Routers = {
  User,
  Home
}

function App() {
  console.log("test");

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Routers.Home />} />
      </Routes>
    </Router>
  );
}

export default App;
