import ataturk from '../../ataturk.png';
import DeveloperPage from '../../models/pages/DeveloperPage';

/**
 * 
 * @param {{content: DeveloperPage}} props 
 * @returns 
 */
function Page(props) {
  let body = props.content.body;
  let params = props.content.params;

  console.log(props);
  //

  //

  return (
    <div className="App">
      <header className="App-header">
        <img src={ataturk} className="App-logo" alt="logo" />
        <p>
          Developer <code>Name</code> {params.user_id != null ? params.user_id : ''}
        </p>
      </header>
    </div>
  );
}

export default Page;