import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import Props from '../models/components/head';

/**
 * 
 * @param {{content: Props}} props 
 * @returns 
 */
function Component(props) {
  let body = props.content.body;
  let params = props.content.params;

  useEffect(() => {
    AOS.init(); 
  }, []);
  
  console.log(props);
  //

  //

  if (props.content.page == null || props.content.page.type == null || props.content.page.type != 'project/details')
    return (
      <Helmet>
        <script src="https://unpkg.com/scrollreveal"></script>
        <script src="https://unpkg.com/scrollreveal@4.0.0/dist/scrollreveal.min.js"></script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Syne:wght@400..800&display=swap" rel="stylesheet" />
        <meta charset="UTF-8" />
        <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="/style.css" />
        <link rel="stylesheet" href="/responsive-style.css" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico"/>
        <title>{props.content.page != null ? props.content.page.title : `MAHMUT GÜVEN TASARIM § İNŞAAT`}</title>

        <script src="scripts/snap-section.js"></script>
        <script src="scripts/smooth-scroll.js"></script>
        <script src="scripts/menu-controller.js"></script>
        <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
        <script src="scripts/tab-controller.js"></script>
      </Helmet>
    );
  else
    return (
      <Helmet>
        <script src="https://unpkg.com/scrollreveal"></script>
        <script src="https://unpkg.com/scrollreveal@4.0.0/dist/scrollreveal.min.js"></script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Syne:wght@400..800&display=swap" rel="stylesheet" />
        <meta charset="UTF-8" />
        <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="/style.css" />
        <link rel="stylesheet" href="/responsive-style.css" />
        <title>{props.content.page != null ? props.content.page.title : `MAHMUT GÜVEN TASARIM § İNŞAAT`}</title>

        <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>

      </Helmet>
    );
}

export default Component;