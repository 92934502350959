import { BrowserRouter as Router, Routes, Route, useSearchParams, useParams } from 'react-router-dom';
import DeveloperApp from '../pages/Developer/Developer';
import PropsContent from '../models/PropsContent';

/**
* @param {*}  
*/
function FetchDetails() {
    return (
        <Routes>
            <Route path="about" element={<DeveloperApp content={{ params: { user_id: "about" } }} />} />
            <Route path=":userId" element={<DeveloperApp content={{ params: { user_id: "s" } }} />} />
        </Routes>
    );
}


export default FetchDetails;